import { Controller } from '@hotwired/stimulus'

export default class ClipboardController extends Controller {
  static targets = ['content', 'button']

  static values = {
    confirmText: { type: String, default: 'Copied!' },
    delay: { type: Number, default: 2500 }
  }

  copy () {
    const text = this.contentTarget.value
    const buttonDefault = this.buttonTarget.innerHTML

    navigator.clipboard.writeText(text)
      .then(() => {
        this.buttonTarget.textContent = this.confirmTextValue
        setTimeout(() => {
          this.buttonTarget.innerHTML = buttonDefault
        }, this.delayValue)
      })
      .catch((error) => {
        console.error('Failed to copy text to clipboard:', error)
      })
  }
}
