import { Application } from 'stimulus'
import consumer from '@/channels/consumer'

export const application = Application.start()

application.consumer = consumer
window.Stimulus = application
// application.debug = true; // outputs Stimulus actions and callbacks in console

export default application
